<template>
  <div class="view-warpper">
    <div class="logo">
      <img src="http://aimianshiguan.oss-cn-beijing.aliyuncs.com/resume/89538240087176770.png" width="120" height="34" />
    </div>
    <div class="title">
      <div class="h1">{{ query.companyName }}</div>
      <div class="h2">
        <!-- query.type + '' === '1' ? '' : '' -->
        <span v-if="this.$route.query?.positionId == '971bc154232c435a8b051dbf972cb9bf'|| this.$route.query?.positionId == '619cde9e5f1a4af185e0bd32ed32eac9'">为平凡生活创造幸福体验</span>
        <span v-else>邀请您参加 【{{ query.positionName }}】 职位的{{ getQueryType(query.type) }}</span>
      </div>
      <div class="itemTime-list" v-if="+query.type === 1">
        <!-- <div class="itemTime" v-if="query.companyName && query.companyName === '中原银行'">
          <div class="label">面试开放时间</div>
          <div class="value">{{ `2023-11-02 20:00至2023-11-03 23:59` }}</div>
        </div> -->
        <div class="itemTime" v-if="aiStartTime && aiEndTime">
          <div class="label">面试开放时间</div>
          <div class="value">{{ `${aiStartTime} 至 ${aiEndTime}` }}</div>
        </div>
        <!-- <div class="item" v-if="query.reponseTime">
          <div class="label">面试响应:</div>
          <div class="value">{{ query.reponseTime }}</div>
        </div> -->
        <div class="itemTime" v-if="interviewIntoTime">
          <div class="label">候考时间</div>
          <div class="value">{{ interviewIntoTime }}</div>
        </div>
        <div class="itemTime" v-if="aiIntention">
          <div class="label">当前状态</div>
          <div class="value">{{ aiIntention }}</div>
        </div>
      </div>
      <div class="itemTime-list" v-else-if="+query.type === 2">
        <div class="itemTime">
          <div class="label" v-if="query.type + '' === '1'">面试有效期:</div>
          <div class="label" v-else>面试时间:</div>
          <div class="value">
            <div>{{ query.validityDate }}</div>
            <!-- <div>2020.03.15 10:00</div> -->
          </div>
        </div>

        <div class="itemTime">
          <div class="label">房间号</div>
          <div class="value">{{ query.interviewRoomNo }}</div>
        </div>

        <div class="itemTime" v-if="query.type + '' === '2' && query.contractInformation">
          <div class="label">联系方式</div>
          <div class="value">{{ query.contractInformation }}</div>
        </div>

        <div class="itemTime" v-if="query.type + '' === '3'">
          <div class="label">候选人</div>
          <div class="value">{{ query.candidateNames }}</div>
        </div>
      </div>
      <div class="itemTime-list" v-else-if="+query.type === 3">
        <div class="itemTime" v-for="(item, index) in acceptTemplateList" :key="index">
          <div class="label">{{ item.label }}</div>
          <div class="value">{{ item.value }}</div>
        </div>
      </div>
      
    </div>
    <div class="content">
      <!--1 AI面试 -->
      <template v-if="+query.type === 1">
        <div class="item invite-status" v-if="this.positionInfo.aiIntention == 1">
          <div class="invite-label">↓↓您已接受面试，请通过下方接入方式进行接入↓↓</div>
        </div>

        <div class="item invite-status" v-else-if="this.positionInfo.aiIntention != 2">
          <div class="bt-status">
            <van-button @click="onAIReject" plain style="border:1px solid #FE574A;font-weight: bold;" color="#FE574A" class="cs-bt">拒 绝</van-button>
            <van-button @click="onAIAccept" type="info" color="#1990FF" class="cs-bt">接 受</van-button>
          </div>
        </div>
      </template>
    </div>
    <div class="method" v-if="this.positionInfo.aiIntention != 2 && +query.type !== 3">
      <div class="tip">接入方式</div>
      <div class="control" v-if="pcUrl">
        <img class="control_icon" src="http://aimianshiguan.oss-cn-beijing.aliyuncs.com/resume/89537388606563318.png" alt="">
        <div class="control_item">
          <span class="label">电脑浏览器接入</span>
          <span class="btn" @click="copy(query.pcVisitUrl)">复制链接</span>
        </div>
      </div>
      <div class="control" v-if="miniAppUrl" style="flex-flow: column;">
        <div class="control_item">
          <div>
            <img class="control_icon" src="http://aimianshiguan.oss-cn-beijing.aliyuncs.com/resume/89537388305607857.png" alt="">
            <span class="label">小程序加入面试</span>            
          </div>
          <span class="btn" @click="join(miniAppUrl)">小程序加入</span>
        </div>
        <div class="tip_text" style="margin: 8px 0;padding-right: 40%;">
          <!-- <span>若小程序无法打开，请复制链接微信打开。</span><span class="btn" @click="copy(currentUrl)">复制链接</span> -->
          <div>若链接无法访问，请用微信小程序搜索{{ `“${+query.type === 1 ? 'AI得贤招聘官' : '得贤远程面试'}”，输入${+query.type === 1 ? '面试密码' : '房间号'}参加面试。` }}</div>
        </div>
        <div class="control_item_psw">
          <span class="label">{{ +query.type === 1 ? '面试密码：' : '房间号：' }}</span>
          <span class="psw">{{ query.interviewPassword }}</span>
        </div>
      </div>
      <div class="control" v-if="h5Url">
        <img class="control_icon" src="http://aimianshiguan.oss-cn-beijing.aliyuncs.com/resume/89537387905652593.png" alt="">
        <div class="control_item">
          <span class="label">手机浏览器</span>
          <span class="btn" @click="join(h5Url)">点击链接</span>
        </div>
      </div>
    </div>
    <div class="button" v-if="query.jobseekerChannelId && +query.type !== 1">
      <!-- 兼容旧数据，旧数据无接受拒绝参数 -->
      <van-button color="#409eff" type="primary" @click="onAccept">接 受</van-button>
      <van-button type="default" @click="onReject">拒 绝</van-button>
    </div>

    <!-- <div class="hotline">技术服务热线：400-0963-520</div> -->

    <van-dialog v-model="show" width="320px" :show-confirm-button="false" :show-cancel-button="false" :close-on-click-overlay="true">
      <div class="qrcode">
        <img :src="query.qrCodeUrl" width="160" v-if="query.type + '' === '1'" />
        <img src="../../assets/weixin.jpg" width="160" v-else />
        <div>长按识别或保存此图片</div>
        <div>微信『扫一扫』中识别小程序</div>
        <div>快速参加面试</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast, Dialog } from 'vant'
import { createwxaqrcode } from '@/api/enterprise'
import { queryInterviewPlan } from '@/api/report'
import { submitAiIntention } from '@/api/candidate'
import { updatePlanInterviewStatus, queryPositionInfo, getOnlineUser } from '@/api/candidate'
import moment from 'moment'

Vue.use(Toast)
Vue.use(Dialog)
export default {
  data() {
    return {
      show: false,
      query: {},
      createwxaqrcode: '',
      interivewWays: ['AI视频面试', '线上面试', '线下面试'],
      interviewPlanKeys: [
        { key: 'interviewTime', label: '面试时间', value: '' },
        { key: 'offlineLocation', label: '面试地点', value: '' },
        { key: 'interviewRoundName', label: '面试轮次', value: '' },
        { key: 'feedbackEmail', label: '问题反馈邮箱', value: '' },
        { key: 'contacts', label: '联系人', value: '' },
        { key: 'contractInformation', label: '联系电话', value: '' },
      ],
      interviewPlan: '',
      acceptTemplateList: [],
      positionInfo: null,
      aiStartTime: '',
      aiEndTime: '',
      h5Url: '',
      miniAppUrl: '',
      pcUrl: '',
      loading: true,
      pisitionIds: [
        '67a7fd56ba6c4010b60eb149c01f1272', // mini
        '23ea362180c44b37a74fd83e01caaeb8',
        '7efc9d7a57fd4c4c9c8bd4bd913e170c',
        '5d7eb312b0164bc9bd9d6fa49c9a9328',
        'e3dbe78ccb1c4d5398f01e5ae7597e47',
        'ef5a58c5495649be97051f2abbac5869',
        'f0429186d37445d3a63fbf9dcb4f1da4',
        'e6490efd17504ef9a9c63262f59df5c8',
        '611700ca01614cc09d16ea60152f0f91',
        'e7bb7905bbe546eb93f4a5b64308eede',
        'a390640b629b47eaa4be4e63b26155fb',
        'fc5aefe92c14445ea4ffae0e5058fd06',
        '158e85f250874820872f7f7df9a22886',
        'cebffd3018ff4ed48ccc07765792d0ba',
        '1ae3ba50fb9c463998e526e2dbd69d45',
        '1bf16f4175fa403d961a896eeff0a629',
      ],
      message: '不要着急，AI面试官正在努力面试中，请过5分钟再试',
      clientLimit: '', //接入方式 0无限制 1 pc 2小程序
      interviewIntoTime: '', //候考时间
    }
  },
  created() {
    this.init()
  },
  methods: {
    getOnlineUser(callback) {
      getOnlineUser({})
        .then(res => {
          if (res && res.code === 0) {
            const { count, limitUser } = res.data
            if (+count > +limitUser) {
              const positionId = this.$route.query?.positionId
              if (positionId && this.pisitionIds.includes(positionId)) {
                this.message = '现在AI面试官正在拼命面试候选人，请您过三十分钟再来和我面试吧😀'
              }
              Dialog.confirm({
                message: this.message,
                showConfirmButton: false,
                showCancelButton: false,
              }).then(() => {})
            } else {
              callback()
            }
          }
        })
        .catch(() => {
          Dialog.confirm({
            message: '不要着急，AI面试官正在努力面试中，请过5分钟再试',
            showConfirmButton: false,
            showCancelButton: false,
          }).then(() => {})
        })
    },
    init() {
      let query = this.$route.query
      if (query) {
        for (let key in query) {
          query[key] = decodeURIComponent(query[key])
        }
      }
      this.query = query
      if (this.query.type === '3') {
        this.queryInterviewPlan()
      }

      if (+this.query.type === 1) {
        this.getOnlineUser(() => {
          this.queryPositionInfo()
        })
      } else {
        this.loading = false
      }
    },
    queryPositionInfo() {
      const { jobseekerChannelId } = this.query
      const pin = this.query.interviewPassword
      queryPositionInfo({ pin, jobseekerChannelId })
        .then(res => {
          if (res && res.code === 0) {
            this.positionInfo = res.data
            const { positionStartTime, positionEndTime, responseTime, h5Url, miniAppUrl, pcUrl, clientLimit, interviewIntoTimeStamp } = this.positionInfo
            const aiEndTime = this.getMinNonZero(Number(positionEndTime), Number(responseTime))
            this.h5Url = h5Url
            this.miniAppUrl = miniAppUrl
            this.pcUrl = pcUrl
            this.aiStartTime = positionStartTime ? moment(+positionStartTime).format('YYYY-MM-DD HH:mm') : ''
            this.aiEndTime = aiEndTime ? moment(+aiEndTime).format('YYYY-MM-DD HH:mm'):'无限制'
            this.clientLimit = clientLimit
            this.interviewIntoTime = interviewIntoTimeStamp ? moment(+interviewIntoTimeStamp).format('YYYY-MM-DD HH:mm') : ''
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getMinNonZero(a, b) {
      // 如果其中一个数为0，则返回另一个数，否则返回较小的那个数
      return a === 0 || b === 0 ? (a === 0 ? b : a) : Math.min(a, b)
    },
    join(url) {
      if (this.loading) {
        return false
      }
      if (!url) {
        this.$notify({ type: 'danger', message: '链接无法访问' })
        return false
      }
      if (this.isFaceCheck) {
        const { jobseekerChannelId, interviewId, posotionId, jobseekerInterviewId } = this.positionInfo
        this.$router.push({
          path: '/idcheck',
          query: {
            jobseekerChannelId,
            interviewId,
            posotionId,
            jobseekerInterviewId,
            redirectUrl: url ? encodeURIComponent(url) : '',
          },
        })
      } else {
        location.href = url
      }
    },
    onAccept() {
      const { jobseekerChannelId, positionId, companyId, interviewPlanId, relateId } = this.query
      updatePlanInterviewStatus({ relateId: relateId }).then(res => {
        if (res && res.code === 0) {
          this.$router.push({
            path: '/emailFeedback',
            query: {
              acceptType: '2',
              jobseekerChannelId,
              positionId,
              companyId,
              interviewPlanId,
            },
          })
        } else {
          this.$notify({ type: 'danger', message: '操作失败' })
        }
      })
    },
    onReject() {
      const { rejectType, jobseekerChannelId, positionId, companyId, relateId } = this.query
      this.$router.push({
        path: '/emailFeedback',
        query: {
          rejectType,
          jobseekerChannelId,
          positionId,
          companyId,
          relateId,
        },
      })
    },

    onAIReject() {
      console.log('onAIReject')
      const { jobseekerChannelId, interviewId, jobseekerInterviewId } = this.positionInfo
      this.$router.push({
        path: '/emailFeedback',
        query: {
          rejectType: 0,
          relateId: jobseekerInterviewId || interviewId,
          interviewId: jobseekerInterviewId || interviewId,
          jobseekerChannelId,
        },
      })
    },

    onAIAccept() {
      console.log('onAIAccept')
      const { jobseekerChannelId, interviewId, jobseekerInterviewId } = this.positionInfo
      if (interviewId || jobseekerInterviewId) {

        submitAiIntention({
          interviewId: jobseekerInterviewId || interviewId,
          aiIntention: 1,
        }).then(() => {

          this.queryPositionInfo()
          this.loading = false
        })


        // this.$router.push({
        //   path: '/emailFeedback',
        //   query: {
        //     acceptType: 3,
        //     interviewId: jobseekerInterviewId || interviewId,
        //     jobseekerChannelId,
        //   },
        // })
      } else {
        // this.$notify({ type: 'warning', message: '参数缺少！' })
      }
    },

    getQueryType(type) {
      const index = type - 1
      if (0 <= index < 3) {
        return this.interivewWays[index]
      } else {
        return ''
      }
    },
    queryInterviewPlan() {
      // this.loading = true
      const data = {
        id: this.$route.query.interviewPlanId,
      }
      queryInterviewPlan(data).then(res => {
        // this.loading = false
        const interviewPlans = res.result || []
        const interviewPlanKeys = JSON.parse(JSON.stringify(this.interviewPlanKeys))
        console.log('interviewPlans', interviewPlans)
        this.interviewPlan = interviewPlans[0]
        // if (this.interviewPlan.planRoomType === 1) {
        //   interviewPlanKeys.push({ key: 'candidatePassword', label: '面试密码', value: '' })
        // }
        this.acceptTemplateList = interviewPlanKeys.map(item => {
          return {
            value: this.getInterviewValue(item),
            label: item.label,
          }
        })
      })
    },
    getInterviewValue(item) {
      // console.log(item)
      if (item.key === 'interviewTime') {
        return this.interviewPlan.interviewDate + ' ' + this.interviewPlan.interviewStartTime + '-' + this.interviewPlan.interviewEndTime
      } else if (item.key === 'offlineLocation') {
        return this.interviewPlan.planRoomType === 0
          ? this.interviewPlan.offlineLocation
          : this.interviewPlan.planRoomType === 1
          ? this.interviewPlan.candidatePcInterviewUrl
          : this.interviewPlan.planRoomType === 3
          ? this.interviewPlan.tencentMeetingUrl
          : ''
      } else {
        return this.interviewPlan[item.key]
      }
    },
    showDialog() {
      createwxaqrcode({
        positionId: this.query.sharepositionId,
      }).then(res => {
        if (res.code === 0) {
          this.createwxaqrcode = 'data:image/jpeg;base64,' + res.data
        }
      })
      this.show = true
    },
    copy(url) {
      let oInput = document.createElement('input')
      oInput.value = url || ''
      document.body.appendChild(oInput)
      oInput.select()
      document.execCommand('Copy')
      oInput.style.display = 'none'
      document.body.removeChild(oInput)
      Toast.success('复制链接成功')
    },
  },
  computed: {
    aiIntention() {
      if (this.positionInfo) {
        const { aiIntention } = this.positionInfo
        return aiIntention ? (aiIntention === 1 ? '接受' : aiIntention === 2 ? '拒绝' : '') : ''
      } else {
        return ''
      }
    },
    showPc() {
      return (+this.query.type === 1 && [0, 1].includes(this.clientLimit)) || +this.query.type === 2
    },
    showSp() {
      return (+this.query.type === 1 && [0, 2].includes(this.clientLimit)) || +this.query.type === 2
    },
    currentUrl() {
      return location.href
    },
    isFaceCheck() {
      return this.positionInfo?.isFaceCheck === 1
    },
  },
}
</script>

<style lang="scss" scoped>
.view-warpper {
  padding: 20px;
  .logo {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 15px;
    span {
      margin-left: 8px;
    }
  }

  .title {
    padding: 30px;
    background:  url('http://aimianshiguan.oss-cn-beijing.aliyuncs.com/resume/89536051179675339.png') no-repeat center;
    text-align: center;
    color: #ffffff;
    border-radius: 8px;
    background-size: cover;
    .h1 {
      font-size: 20px;
      font-weight: bold;
    }
    .h2 {
      margin-top: 15px;
      font-size: 16px;
    }
  }
  .itemTime-list{
    width: 100%;
    background: linear-gradient( 180deg, rgba(255,255,255,0.9) 0%, #FFFFFF 100%);
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08);
    border-radius: 8px;
    margin-top: 8px;
    
    .itemTime:last-child{
      border: 0;
    }
    .itemTime{
    padding: 5px 0;

      text-align: center;
      border-bottom: 1px solid #E5E5E5;
      .label{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #333333;
        line-height: 24px;
        font-style: normal;
      }
      .value{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        line-height: 22px;
        font-style: normal;
      }
      
    }
  }
  

  .content {
    margin: 15px 0;
    .item {
      display: flex;
      // padding: 15px 10px;
      border-bottom: 1px solid #f2f2f2;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      .label {
        color: #a6a6a6;
      }
      .value {
        font-weight: bold;
      }
    }
    .invite-status {
      flex-direction: column;
      align-items: baseline;
      border: 0;
      .c-status {
        color: #d9d9d9;
        margin-bottom: 8px;
      }
      .bt-status {
        display: flex;
        justify-content: space-between;
        width: 100%;
        // padding: 0 20px;
        .cs-bt {
          width: 48%;
          border-radius: 5px;
        }
      }
      .invite-label{
        border: 1px solid #2C9AFF;
        text-align: center;
        width: 100%;
        padding: 10px 0;
        border-radius: 3px;
      }
    }
    .content-item {
      display: flex;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      margin-bottom: 12px;
      .content-item_label {
        width: 120px;
        min-width: 120px;
        text-align: left;
      }
      .content-item_value {
        word-break: break-word;
      }
    }
  }

  .method {
    .tip {
      font-size: 18px;
      margin-bottom: 10px;
    }
    .control {
      display: flex;
      // flex-flow: column;
      padding: 15px;
      background: #F5F7FA;
      align-items: center;
      font-size: 16px;
      margin-bottom: 10px;
      border-radius: 8px;
      // border-left: 5px solid #2c9aff;
      .control_icon{
        height: 24px;
        width: 24px;
        margin-right: 5px;
      }
      .control_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        >div{
          display: flex;
        }
        
      }
      .label {
        color: #333;
        font-weight: bold;
      }
      .btn {
        display: inline-block;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid #2C9AFF;
        color: #2C9AFF;
        background: #ffffff;
        padding: 5px 0;
        text-align: center;
        width: 100px;
      }
      .control_item_psw{
        background: rgba(44, 154, 255, 0.1);
        border-radius: 4px;
        text-align: center;
        width: 100%;
        height: 40px;
        line-height: 40px;
        .psw {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 16px;
          color: #2C9AFF;
        }
      }
    }
    .mobile_tip {
      display: flex;
      justify-content: center;
      .btn {
        cursor: pointer;
        color: #5ba6ef;
      }
    }
    .tip_text {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 13px;
      color: #666666;
      width: 100%;
      line-height: 1.5;
    }
  }

  .hotline {
    margin: 30px 0;
    font-size: 16px;
    color: #8f8f8f;
    text-align: center;
  }
  .qrcode {
    margin: 30px 0;
    text-align: center;
    div {
      margin-top: 8px;
    }
  }
  .button {
    display: flex;
    flex-direction: column;
    .van-button + .van-button {
      margin-top: 20px;
    }
  }
}
</style>
